import React, { Component } from "react";

class Brand extends Component{
    render(){
        const {branstyle } = this.props;
        return(
            <React.Fragment>
                <ul className={`brand-list ${branstyle}`}>
                    <li>
                        <img src="/assets/images/brand/brand-01.png" alt="COCA-COLA"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-02.png" alt="LICORERANACIONAL"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-03.png" alt="GATORADE"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-04.png" alt="MARLBORO"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-05.png" alt="BIMBO"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-06.png" alt="BRAHVA"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-18.png" alt="CORONA"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-19.png" alt="NATUREVALLEY"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-20.png" alt="PEPSI"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-21.png" alt="CHIVAS"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-22.png" alt="REDBULL"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-23.png" alt="COLGATE"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default Brand;