import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import Header from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import Team from "../blocks/team/TeamTwo";
import Helmet from "../component/common/Helmet";
import { FiChevronUp } from "react-icons/fi";
import BrandOne from "../elements/Brand";
import BrandTwo from "../elements/BrandTwo";


const SlideList = [
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--31',
        category: '',
        title: 'SUPERMART',
        description: 'Ecuentra productos en volumen y variedad para satisfacer tus necesidades',
        buttonText: 'Contactanos',
        buttonLink: 'mailto:info@supermart24.net'
    }
]


class CorporateBusiness extends Component{
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){

        return(
            <Fragment> 
                <Helmet pageTitle="SUPERMART" />

                {/* Start Header Area  */}
                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <div className="slider-activation">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value , index) => (
                                <div className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* End Slider Area   */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-4.png" alt="About Images"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Acerca de Nosotros</h2>
                                        <p>En Supermart nos esmeramos para darte el mejor servicio y poner a tu disposición los mejores productos para tu convenienvia!</p>
                                        <p>Nuestra misión es brindarte producto de la mas alta calidad, en tiendas con seguridad, tranquilidad y comodidad.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start Team Area  */}
                <div className="rn-team-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                    <h2 className="title">Equipo a tu servicio</h2>
                                    <p>Nuestro amigable personal esta a su disposicion cumpliendo con la mision de Supermart, expandiendo los limites de la comodidad a nuevos horizontes.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <Team column="col-lg-3 col-md-6 col-sm-6 col-12 mt--30" teamStyle="" item="4" />
                        </div>
                        <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                    <br/>
                                    <br/>
                                    <h4>Supermart Flores</h4>
                                    <h5>Calle 30 de Junio, Flores, Guatemala</h5>
                                    <br/>
                                    <h4>Supermart Texaco Aeropuerto</h4>
                                    <h5>Flores, Guatemala</h5>
                                    <br/>
                                    <h4>Supermart Texaco Fallabon</h4>
                                    <h5>Melchor de Mencos, Guatemala</h5>
                                    <br/>
                                    <h4>Supermart Galerias Primma</h4>
                                    <h5>Centro Comercial Galerías Primma, Calzada Roosevelt 13-46, Guatemala</h5>
                                </div>

                    </div>
                </div>
                {/* End Team Area  */}

                {/* Start Brand Area  */}
                <div className="rn-brand-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                        <p>ALGUNAS DE LAS MARCAS QUE COMERCIALIZAMOS:</p>
                        <br/>
                            <div className="col-lg-12">
                                <BrandOne branstyle="branstyle--2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}

                <div className="rn-brand-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                        <p>ALGUNOS DE LOS ARTICULOS QUE OFRECEMOS:</p>
                        <br/>
                            <div className="col-lg-12">
                                <BrandTwo branstyle="branstyle--2" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start call To Action  */}
                <CallAction />
                {/* End call To Action  */}

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default CorporateBusiness;