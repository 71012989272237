import React from 'react'
import WhatsAppWidget from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';

const CallAction = () => {
    return (
        <div className="call-to-action-wrapper call-to-action text-white-wrapper  ptb--120">
            <div className="container">
            <WhatsAppWidget phoneNumber='50242719169' textReplyTime='Estaremos respondiendo a la mayor brevedad posible.' message='Hola! 👋🏼 ¿Como te llamas y como podemos ayudarte?' companyName='Supermart' sendButton='Enviar'/>  
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner text-center">
                            <span></span>
                            <h3>Visitanos!</h3> 
                            <h4>Horarios</h4>
                            <h5>6:00 - 21:00 (LUNES - DOMINGO)</h5>
                            <a className="rn-button-style--2" href="mailto:info@supermart24.net"><span>Contactanos</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CallAction;