import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/brand-07.png" alt="BARRAS"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-08.png" alt="BEBIDASENERGETICAS"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-09.png" alt="AGUA"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-10.png" alt="LICOR"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-11.png" alt="CERVEZA"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-12.png" alt="BOTANAS"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-13.png" alt="GALLETAS"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-14.png" alt="DULCES"/>
                    </li>
                    {/* <li>
                        <img src="/assets/images/brand/brand-15.png" alt="REFRESCOS"/>
                    </li> */}
                    <li>
                        <img src="/assets/images/brand/brand-16.png" alt="JUGOS"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-17.png" alt="CIGARROS"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;